<template>
    <div id="answerQuestionSubmitPage">
        <div style="margin-bottom: 10px;height: 30px;font-weight: bold;" class="submitPagePadding" ><span >填报人邮箱</span></div>
        <div style="background-color: white;padding-top:10px;padding-bottom: 10px;" class="submitPagePadding" >
            <el-input
                    placeholder="请输入填报人邮箱"
                    v-model="userInfo.email">
            </el-input>

            <div style="color: #E6A23C;font-weight: bold;padding: 10px 0 0;" v-if="parsedParam.downloadAfterAnswerSheetSubmit">
                当前文档将以word附件的形式，发送到上述邮箱，请注意查收。
            </div>


            <div style="text-align: center;margin-top: 15px;">
                <el-button type="primary" style="width: 50%;" @click="onSubmit" :disabled="submitDisable">{{parsedParam.downloadAfterAnswerSheetSubmit?'导出':'提交'}}</el-button>
            </div>

        </div>
    </div>
</template>
<style lang="scss">
    #answerQuestionSubmitPage{
        background-color: #F9F9F9;
        text-align: left;
        padding-top:15px;
        height: 100vh;
        .el-input__inner {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #DCDFE6;
        }
    }
    .submitPagePadding{
        padding:0 10px;
    }
</style>

<script>
    import {exportAnswerSheetToEmail, submitAnswerSheet} from "../api/api";
    import {ElLoading} from "element-plus";
    import {CUSTOM_EVENT, logToES} from "../assets/js/tag";

    export default {
        name: 'AnswerSheet',

        data: function () {
            return {
                userInfo: {
                    email: localStorage.getItem('respondentEmail')
                },
                parsedParam: {
                    downloadAfterAnswerSheetSubmit: false,
                    authInfo: null,
                },
                fileId: -1,
                answerSheetId:null,
                submitParam: null,
                submitDisable: false
            };
        },
        watch:{
            'userInfo.email':function (val) {
                localStorage.setItem('respondentEmail',val)
            }
        },
        computed: {

        },
        mounted: function () {
            console.log(this.$route.params)
            try{
                const {paramJSON,permissionJSON,fileId} = this.$route.params;
                this.submitParam = JSON.parse(paramJSON);
                this.parsedParam = JSON.parse(permissionJSON)
                this.fileId = fileId;
            }catch (e) {
                console.error(e);
                this.$message.error('参数传递错误，请退回到前一步')
            }
        },
        methods: {
            onSubmit:function () {
                if(!this.validateEmail()){
                    return;
                }
                this.submitParam.email = this.userInfo.email;
                this.submitParam.sendEmail = this.parsedParam.downloadAfterAnswerSheetSubmit;
                this.submitParam.publicUserAuthToken = this.parsedParam.authInfo;

                this.submitDisable = true;
                submitAnswerSheet(this.fileId,this.submitParam).then(res => {
                    this.clearAnswerSheetCache();
                    if (res.data.code === 0) {
                        const downloadAfterSubmit =this.parsedParam.downloadAfterAnswerSheetSubmit;
                        const newAnswers = res.data.data;
                        if (downloadAfterSubmit && newAnswers && newAnswers.length > 0) {
                            this.answerSheetId = newAnswers[0].answerSheetId;
                            this.exportToEmail(this.answerSheetId);
                        } else {
                            this.$message.success('提交成功');
                        }
                    }else {
                        this.$message.error('提交失败');
                    }
                }).catch(error => {
                    console.error(error);
                    this.$message.error('提交失败');
                }).finally(()=>{
                    this.submitDisable = false;
                });
                logToES({event_name:CUSTOM_EVENT.AQ_SEND_EMAIL.name,value:1, text: CUSTOM_EVENT.AQ_SEND_EMAIL.desc+',email:'+this.userInfo.email})
            },
            validateEmail:function () {
                const emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                if (!this.userInfo.email) {
                    this.$message.error('请填写邮箱信息')
                    return false;
                }
                if(!emailReg.test(this.userInfo.email)){
                    this.$message.error('请输入正确的邮箱')
                    return false;
                }
                return true;
            },
            clearAnswerSheetCache:function () {
                localStorage.removeItem(`answerCache${this.fileId}`)
            },
            exportToEmail:function (answerSheetId) {
                this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading',text:'正在发送文档到您的邮箱'});
                exportAnswerSheetToEmail(answerSheetId, this.parsedParam.authInfo).then(res=>{
                    if (res.data.code === 0) {
                        // this.$message.success('导出成功');
                        this.loadingInstance.setText('发送成功')
                    }else{
                        // this.$message.error(res.data.msg);
                        this.loadingInstance.setText(res.data.msg)
                    }
                }).catch(err=>{
                    console.error(err);
                    // this.$message.error("导出失败");
                    this.loadingInstance.setText("发送失败")
                }).finally(()=>{
                    setTimeout(() => {
                        this.loadingInstance.close()
                    }, 800);
                });
            }
        },
        components: {

        }
    };
</script>
